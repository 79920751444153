<template>
  <div>
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <pricing-section />
  </div>
</template>

<script>
  // import axios from 'axios'

  export default {

    components: {
      PricingSection: () => import('../components/PricingSection'),
    },

    data: () => ({
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      isOverlayVisible: false,
      username: '',
      user: null,
    }),

    async created () {
      this.usermail = localStorage.getItem('username')
    },

    methods: {

    },
  }

</script>
